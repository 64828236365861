<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="tabToggle">
      <el-tab-pane
        v-for="(item, index) in navList"
        :key="index"
        :label="item.key"
        :name="item.val"
      />
    </el-tabs>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: '',
      navList: [
        {
          key: '采购申请单',
          val: '/purchase/apply/list'
        },
        {
          key: '采购订单',
          val: '/purchase/order/list'
        }
      ]
    }
  },
  mounted() {
    this.activeName = this.$route.path
  },
  methods: {
    tabToggle() {
      this.$router.push(this.activeName)
    }
  }
}
</script>

<style lang="less" scoped></style>
