<template>
  <div class="page-main">
    <PurchaseTabs />
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <!-- <el-button type="success" size="mini" @click="itemPass">审核通过</el-button> -->
        <!-- <el-button type="danger" size="mini" @click="itemReject">审核拒绝</el-button> -->
        <el-button type="primary" size="mini" @click="itemPayout">开付款单</el-button>
        <el-button type="danger" size="mini" @click="itemDelete">删除</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 187"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      :merge-cells="mergeCell"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.cai_gou_bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_cai_gou_bill_date="{ row }">
        {{ row.cai_gou_bill_date ? row.cai_gou_bill_date.slice(0, 10) : '' }}
      </template>
      <template #default_cai_gou_code="{ row }">
        <el-link type="primary" @click="itemPrint(row)"> {{ row.cai_gou_code }} </el-link>
      </template>
      <template #default_fu_kuan_status="{ row }">
        <span v-if="row.fu_kuan_status === '欠款'" :style="{ color: '#f45' }">
          {{ row.fu_kuan_status }}
        </span>
        <span v-if="row.fu_kuan_status === '已结清'" :style="{ color: '#67c23a' }">
          {{ row.fu_kuan_status }}
        </span>
      </template>
      <template #default_fu_kuan_ji_lu="{ row }">
        <el-link v-if="row.sum_shi_fu_money" type="primary" @click="itemFuKuanDetail(row)">
          查看
        </el-link>
      </template>
      <template #default_bill_code="{ row }">
        <el-link type="primary" @click="itemDetail(row)"> {{ row.bill_code }} </el-link>
      </template>
      <template #default_test_standard="{ row }">
        <el-link type="primary" @click="openPopupTest(row)"> 查看 </el-link>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 开付款单 -->
    <PopupFk
      v-if="isShowPopupFk"
      :show.sync="isShowPopupFk"
      :caiGouBillId="activeObj.cai_gou_bill_id"
      @success="searchListData"
    />

    <!-- 查看付款 -->
    <PopupCkfk
      v-if="isShowPopupCkfk"
      :show.sync="isShowPopupCkfk"
      :caiGouBillId="activeObj.cai_gou_bill_id"
    />

    <!-- 检测标准 -->
    <b-popup-test v-if="isShowPopupTest" :show.sync="isShowPopupTest" :data="activeObj" />
  </div>
</template>

<script>
import PurchaseTabs from '../../common/purchase-tabs'
import PopupFk from '../../../common/popup-fk'
import PopupCkfk from '../../../common/popup-ckfk'

export default {
  components: {
    PurchaseTabs,
    PopupFk,
    PopupCkfk
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        cai_gou_bill_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },
      sortData: {
        cai_gou_stock_id: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/caigou/searchHeard',
      mergeCell: [],
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          isMerge: true,
          width: 40
        },
        {
          title: 'ID',
          field: 'id',
          isMerge: true,
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '采购日期',
          field: 'cai_gou_bill_date',
          width: 180,
          isMerge: true,
          slots: { header: 'header_daterange', default: 'default_cai_gou_bill_date' }
        },
        {
          title: '采购单号',
          field: 'cai_gou_code',
          width: 110,
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_cai_gou_code' }
        },
        {
          title: '供应商',
          field: 'supplier_company_name',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '欠款金额(元)',
          field: 'qian_kuan_money',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '付款状态',
          field: 'fu_kuan_status',
          isMerge: true,
          slots: { header: 'header_autocomplete', default: 'default_fu_kuan_status' }
        },
        {
          title: '付款记录',
          isMerge: true,
          slots: { default: 'default_fu_kuan_ji_lu' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '物料号',
          field: 'wu_liao_code',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code',
          slots: { header: 'header_autocomplete' },
          formatter: ({ cellValue }) => {
            return cellValue.join(',')
          }
        },
        {
          title: '销售单号',
          width: 90,
          field: 'bill_code',
          slots: { header: 'header_autocomplete', default: 'default_bill_code' }
        },
        {
          title: '商品类型',
          field: 'type_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '品名',
          field: 'craft',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '工序',
          field: 'process_order_name'
        },
        {
          title: '工艺',
          field: 'process_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '材质',
          field: 'texture',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '规格',
          width: 120,
          field: 'specification',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单位',
          field: 'unit',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '采购数量',
          field: 'cai_gou_stock_counts',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '采购单价(元)',
          field: 'cai_gou_stock_price',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '采购金额(元)',
          field: 'cai_gou_stock_amount',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '检测标准',
          field: 'test_standard',
          slots: { header: 'header_autocomplete', default: 'default_test_standard' }
        },
        {
          title: '商品备注',
          field: 'cai_gou_remark_stock',
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      },
      totalObj: {
        qian_kuan_money_all: '',
        sum_cai_gou_amount: ''
      },

      isShowPopupFk: false, // 是否显示开付款单
      isShowPopupCkfk: false, // 是否显示查看付款
      isShowPopupTest: false, // 是否显示产品工艺管控卡弹窗
      activeObj: ''
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/caigou/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.totalObj.qian_kuan_money_all = res.data.qian_kuan_money_all
            this.totalObj.sum_cai_gou_amount = res.data.sum_cai_gou_amount
            this.mergeCell = this.$tool.getMergeCellConfigList(
              this.tableData,
              this.tableColumn,
              'cai_gou_code'
            )
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'qian_kuan_money') return this.totalObj.qian_kuan_money_all
          if (column.field === 'cai_gou_stock_amount') return this.totalObj.sum_cai_gou_amount
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row, true) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-详情
    itemDetail(row) {
      window.open(`/sale/verify/detail?id=${row.bill_id_b}`, '_blank')
    },
    // 项-查看付款记录
    itemFuKuanDetail(row) {
      this.activeObj = row
      this.isShowPopupCkfk = true
    },
    // 项-打印
    itemPrint(row) {
      window.open(`/purchase/order/print?code=${row.cai_gou_code}`, '_blank')
    },
    // 审核通过
    // itemPass() {
    //   let row = this.getRadioRecord()
    //   if (row) {
    //     this.itemPassAction(row)
    //   } else {
    //     this.itemChoosePrompt()
    //   }
    // },
    // 项-审核通过
    // itemPassAction(row) {
    //   this.$confirm(`采购单号：${row.cai_gou_code}，该订单是否通过审核?`, '提示')
    //     .then(() => {
    //       this.itemPassConfirm(row.cai_gou_bill_id)
    //     })
    //     .catch(() => {})
    // },
    // 项-审核通过-确认
    // itemPassConfirm(cai_gou_bill_id) {
    //   this.$api({
    //     method: 'get',
    //     url: '/admin/gcvip/caigou/changeStatus',
    //     params: {
    //       cai_gou_bill_id,
    //       status_name: '已审核'
    //     }
    //   }).then((res) => {
    //     if (res.data.state === 'ok') {
    //       this.$message.success('审核已通过')
    //       this.getListData()
    //     } else {
    //       this.$message.error(res.data.msg)
    //     }
    //   })
    // },
    // 审核拒绝
    // itemReject() {
    //   let row = this.getRadioRecord()
    //   if (row) {
    //     this.itemRejectAction(row)
    //   } else {
    //     this.itemChoosePrompt()
    //   }
    // },
    // 项-审核拒绝
    // itemRejectAction(row) {
    //   this.$confirm(`采购单号：${row.cai_gou_code}，该订单是否驳回审核?`, '提示')
    //     .then(() => {
    //       this.itemRejectConfirm(row.cai_gou_bill_id)
    //     })
    //     .catch(() => {})
    // },
    // 项-审核拒绝-确认
    // itemRejectConfirm(cai_gou_bill_id) {
    //   this.$api({
    //     method: 'get',
    //     url: '/admin/gcvip/caigou/changeStatus',
    //     params: {
    //       cai_gou_bill_id,
    //       status_name: '待审核'
    //     }
    //   }).then((res) => {
    //     if (res.data.state === 'ok') {
    //       this.$message.success('审核已拒绝')
    //       this.getListData()
    //     } else {
    //       this.$message.error(res.data.msg)
    //     }
    //   })
    // },
    // 项-开付款单
    itemPayout() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPayoutAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-开付款单
    itemPayoutAction(row) {
      this.activeObj = row
      this.isShowPopupFk = true
    },
    // 项-删除
    itemDelete() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemDeleteAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-删除
    itemDeleteAction(row) {
      this.$confirm(`id：${row.cai_gou_bill_id}，您确认要删除该数据?`, '提示')
        .then(() => {
          this.itemDeleteConfirm(row.cai_gou_bill_id)
        })
        .catch(() => {})
    },
    // 项-删除-确认
    itemDeleteConfirm(cai_gou_bill_id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/caigou/delete',
        params: {
          cai_gou_bill_id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('删除成功')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 检测标准
    openPopupTest(row) {
      this.activeObj = row
      this.isShowPopupTest = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
